.markdown, .renderedMarkdown {
  p, pre {
    margin: 1em auto;
  }
  pre {
    color: black;
    font-weight: normal;
    white-space: pre-wrap;
    background: none;
    padding: 0px;
    overflow-wrap: break-word;
  }

  code {
    font-size: 14px;
    padding: 5px 7px;

    border-radius: 4px;
    background: rgba($info-code-background-color,.05);

    @include text_code($info-code-font-color);
  }

  pre > code {
    display: block;
  }
}
